@import '_variables';

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;

}
