@import '_base';

$avatarSize: 128px;
$avatarSizeSmall: 96px;
$avatarBorder: 4px;
$avatarBorderSmall: $avatarBorder;

$mainPadding: 24px;

@mixin mobile {
  @media only screen and (max-width: 360px) { @content; }
}

// Teh stylez

body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.top {
  background-color: #fff;
}

.bottom {
  width: 100%;
  background-color: $colorPrimary;
}

.top,
.bottom {
  flex: 1 0 auto;
}

.content-container {
  position: relative;
  width: 100%;
  background-color: $colorPrimary;
  display: flex;
  justify-content: center;
  flex: 0 0 auto;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    width: 100%;

    height: $avatarSize / 2 + $avatarBorder + $mainPadding;

    @include mobile {
      height: $avatarSizeSmall / 2 + $avatarBorderSmall + $mainPadding;
    }
  }
}

.content {
  position: relative;
  padding: $mainPadding;
  max-width: 600px;
  color: rgba(#fff, 0.9);
  display: flex;
  flex-direction: column;

  font-size: 24px;

  line-height: 32px;

  a {
    display: inline-block;
    position: relative;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    z-index: 0;

    &::before {
      position: absolute;
      content: '';
      background-color: darken($colorPrimary, 7%);
      background-position: 50% 0;
      left: -6px;
      right: -6px;
      border-radius: 5px;
      top: 0;
      bottom: 0;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
    }

    &:hover::before {
      opacity: .5;
      visibility: visible;
    }

    &:active::before {
      opacity: 1;
      visibility: visible;
    }
  }

  p:first-of-type {
    margin-top: 32px;
    @include mobile { margin-top: 24px; }
  }

  p:last-of-type {
    margin-bottom: 32px;
    @include mobile { margin-bottom: 24px; }
  }

  p {
    max-width: 400px;
    // text-overflow: wrap;
    text-align: center;
    margin: 6px auto;
    @include mobile { margin: 8px; }
  }
}

.avatar {
  width: $avatarSize;
  height: $avatarSize;

  @include mobile {
    width: $avatarSizeSmall;
    height: $avatarSizeSmall;
  }

  background: url('../images/avatar.jpg');
  background-size: cover;
  border-radius: 50%;
  background-color: #525252;
  align-self: center;

  border: $avatarBorder solid white;

  @include mobile {
    border: $avatarBorderSmall solid white;
  }
}

.icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  margin: 0 -8px; // account for extra icon padding
  @include mobile { margin: 0; }

  a {
    display: block;
    width: 32px;
    height: 32px;
    padding: 8px 12px ; // make the touch target bigger and spce between elements

    &::before {
      left: 0;
      right: 0;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;

      background-size: 32px 32px;
      @include mobile { background-size: 24px 24px; }
    }
  }
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  margin: 0 -8px; // account for extra icon padding
  @include mobile { margin: 0; }

  a {
    display: block;
    // width: 32px;
    height: 32px;
    padding: 8px 12px ; // make the touch target bigger and spce between elements

    &::before {
      left: 0;
      right: 0;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;

      background-size: 32px 32px;
      @include mobile { background-size: 24px 24px; }
    }
  }
}
